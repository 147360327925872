import React ,{useState,useEffect} from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useForm,setValue } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';

import http from "../../http";
import { BsFillCursorFill} from "react-icons/bs";
import {lang} from '../enum/enum'
import './event.css';
import TextLineLoader from '../../components/Loaders/TextLineLoader';
import loginCheck from '../../helpers/loginCheck';
import Layout from '../../components/layouts/layout';
import Cookies from 'universal-cookie';
import { SelectPicker, DatePicker,DateRangePicker} from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin'

const EventEdit = () => {
    const {id} = useParams()
    const [events, setEvents] = useState('');
    const {register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [getFrom,setFrom] = useState('')
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const cookies = new Cookies();
    const image = process.env.REACT_APP_IMAGE_BASE_URL
    const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } = DateRangePicker;
    const [imageFormValues,setImageFormValues] = useState('');

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });

    useEffect(()=>{
      fetchAllParents();
    },[]);

    const fetchAllParents = () => {
        http.get('/event/parent', {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
            setParents(res.data.data);
        })
    }

    useEffect(()=>{
      if(events.department_id)
         getDeptSearch()
    },[events]);
  
  
  const getDeptSearch = (val = "") =>{
      let val1 = val.length > 0 ? val : '*';
      if(val.length > 0) {
        http.get(`/department/search/${val}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setDepartments(response.data.data);
        })
      }else if(events.department_id){
        http.get(`/department/get/${events.department_id}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setDepartments({'data': [response.data.data]});
        })
      } else {
        http.get(`/department/search/${val1}`, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then((response)=>{
          setDepartments({'data': []});
        })
      }
  }

    useEffect(()=>{
      fetchEditEvents();
    },[]);

    const fetchEditEvents = () => {
        http.get('event/get/'+id, {
          headers:{
           "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
          }  
      }).then(res=>{
          if(res.data.data.from){
            let fromDate = res.data.data.from
            let tempDateFrom = new Date(fromDate).toUTCString().toString();
            let dateFrom =  tempDateFrom.substring(0, 16)
            let timeFrom =  tempDateFrom.substring(17, 26) 
            res.data.data.from = formateDate(dateFrom+" "+timeFrom+" "+"GMT+0600 (Bangladesh Standard Time)")
            // console.log(res.data.data.from)
          }
          if(res.data.data.to){
            let toDate = res.data.data.to
            let tempDateTo = new Date(toDate).toUTCString().toString();
            let dateTo =  tempDateTo.substring(0, 16)
            let timeTo =  tempDateTo.substring(17, 26) 
            res.data.data.to = formateDate(dateTo+" "+timeTo+" "+"GMT+0600 (Bangladesh Standard Time)")
          }
          // console.table(res.data.data)
          setEvents(removeEmpty(res.data.data));
        })
    }
    
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
      );
    }

    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const image_url = data.image_url[0];
        delete events.image_url;
        delete events.department
        delete events.lang_parent
        data = {...data, ...events};
        data.user_id = cookies.get('userAuth').id

        // data = removeEmpty(data)
        data.image_url = image_url;

        let slider_url;
        if(data.slider_url != undefined) {
            slider_url = [...data.slider_url]
        }
        data = removeEmpty(data);
        
        let hasNonImage = slider_url.map((val)=>{
          return ["image/jpeg", "image/png", "image/jpg" ].includes(val.type)
          }           
        ).includes(false)
    
        if(hasNonImage){
          setImageFormValues("The File field must be a file of type: png, jpg, jpeg")
          return
        } 
        data.slider_url = slider_url;

      setLoader(true)
      http.post('/event/update/'+id,data,{
        headers:{
            "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
           }  
      })
      .then((res)=>{
        console.log(res);
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(()=>{
          navigate("/event");
          },500)
        })
      .catch((err)=> {
        setLoader(false);
          // console.log(Object.keys(err.response.data.data))
          if(err.response.data.message == "Validation Error."){
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
          }
          else{
            setLangError(err.response.data.data)
          }
      })
  }

  const onError = (errors, e) => console.log(errors, e);

  const handleChange = (e) =>{
    console.log(e);
    let event = {...events};
    event[e.target.name] = e.target.value
    setEvents(event);
}

const formateDate = (val) =>{
  console.log(val)
  let tempDate = new Date(val).toUTCString().toString();
  let fetchDate = tempDate.substring(5, 7);

  let monthConvert = 
  tempDate.substring(8, 11)=='Jan' ? "01" :
  tempDate.substring(8, 11)=='Feb' ? "02" :
  tempDate.substring(8, 11)=='Mar' ? "03" :
  tempDate.substring(8, 11)=='Apr' ? "04" :
  tempDate.substring(8, 11)=='May' ? "05" :
  tempDate.substring(8, 11)=='Jun' ? "06" :
  tempDate.substring(8, 11)=='Jul' ? "07" :
  tempDate.substring(8, 11)=='Aug' ? "08" :
  tempDate.substring(8, 11)=='Sep' ? "09" :
  tempDate.substring(8, 11)=='Oct' ? "10" :
  tempDate.substring(8, 11)=='Nov' ? "11" : 
  tempDate.substring(8, 11)=='Dec' ? "12" : null
  // let date = fetchDate + tempDate.substring(8, 11) + ", " + tempDate.substring(12, 16) + " at " 
  let date = tempDate.substring(12, 16) + "-" + monthConvert + "-" + fetchDate    
  // console.log(date)

  let time = new Date(val).toLocaleTimeString('en-US',{
      hour12: false,
    });


  var combineDate = date +" "+ time;
  console.log(combineDate);
  return combineDate;

}


  return (
    <>
    <Layout>

    { getLoader && 
        <div className="preloader">
            <div className="overlay__inner">
                <div className="overlay__content"><span className="spin"></span></div>
            </div>
        </div>
      }

      {events == "" ? 
              <><TextLineLoader/><TextLineLoader/><TextLineLoader/></>:
      <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Edit Event</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                  <Form.Label className="form_label">Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="title"
                      defaultValue={events.title}
                      placeholder="Enter title"
                      // {...register("title")}
                      onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.title? 
                  <span className="text-danger">
                    {getErrorObj.title}
                  </span> : null
                  }
              </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Sub Title</Form.Label>
                  <Form.Control
                      type="text"
                      name="sub_title"
                      defaultValue={events.sub_title}
                      placeholder="Enter Sub title"
                      // {...register("sub_title")}
                      onChange={(e) => handleChange(e)}
                  />
                  {getErrorObj.sub_title? 
                  <span className="text-danger">
                    {getErrorObj.sub_title}
                  </span> : null
                  }
              </Form.Group>
                
              <Form.Group>
                    <Form.Label className="form_label">Department</Form.Label>
                    <SelectPicker 
                          defaultValue={events.department_id}
                          data={departments.data?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onSearch={(val)=>getDeptSearch(val)}
                          onChange={(value) => setEvents({...events, "department_id": value})}
                      />
                    {getErrorObj.department_id? 
                    <span className="text-danger">
                      {getErrorObj.department_id}
                    </span> : null
                    }
                </Form.Group>

              <Form.Group>
                  <Form.Label className="form_label">Image url</Form.Label>
                  <Form.Control
                      type="file"
                      name="image_url"
                      placeholder="Enter Image file"
                      {...register("image_url")}
                  />
                  <p>
                    {
                      (events.image_url) ? <a href={image+'/'+events.image_url} target="_blank"><BsFillCursorFill/> </a> : ''
                    }
                  </p>
                  {getErrorObj.image_url? 
                  <span className="text-danger">
                    {getErrorObj.image_url}
                  </span> : null
                  }
              </Form.Group>


              <Form.Group>
                  <Form.Label className="form_label">Slider File URL</Form.Label>
                  <Form.Control
                      type="file"
                      name="slider_url"
                      placeholder="Enter Slider file"
                      {...register("slider_url")}
                      multiple
                  />
                  {
                      getErrorObj.slider_url?
                          <span className="text-danger">
                          {getErrorObj.slider_url}
                          </span> : null
                  }
                  {
                      imageFormValues ? <span className="text-danger">
                      {imageFormValues}
                      </span> : null
                  }
              </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">From Date</Form.Label>
                    <DatePicker
                        // defaultValue={(events.from) ? new Date(events.from) : ""}
                        defaultValue={(events.from) ? new Date(events.from) : ""}
                        format="yyyy-MM-dd hh:mm:ss aa"
                        showMeridian
                        oneTap
                        showWeekNumbers
                        style={{ width: "100%" }}
                        disabledDate={beforeToday()}
                        onChange={(value) => setEvents({...events, "from": formateDate(value)})}
                    />
                    {getErrorObj.from? 
                    <span className="text-danger">
                      {getErrorObj.from}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">To Date</Form.Label>
                    <DatePicker
                        // defaultValue={(events.to) ? new Date(events.to) : ""}
                        defaultValue={(events.to) ? new Date(events.to) : ""}
                        format="yyyy-MM-dd hh:mm:ss aa"
                        showMeridian
                        showWeekNumbers
                        oneTap
                        style={{ width: "100%" }}
                        disabledDate={beforeToday()}
                        // disabledDate={['2022-10-01','2022-10-02']}
                        onChange={(value) => setEvents({...events, "to": formateDate(value)})}
                    />
                    {getErrorObj.to? 
                    <span className="text-danger">
                      {getErrorObj.to}
                    </span> : null
                    }
                </Form.Group>

              <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    <SelectPicker 
                          defaultValue={events.lang_parent_id}
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setEvents({...events, "lang_parent_id": value})}
                      />
                      {getErrorObj.lang_parent_id? 
                      <span className="text-danger">
                        {getErrorObj.lang_parent_id}
                      </span> : null
                      }
                      {getLangError? 
                        <span className="text-danger">
                          {getLangError}
                        </span> : null
                      }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    <SelectPicker 
                          defaultValue={events.lang}
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setEvents({...events, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>



              <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        config={{
                          extraPlugins: [uploadPlugin]
                        }}
                        data={events.description}
                        editor={ FullEditor }
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setEvents({...events, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>

              <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                      Update
                  </Button>
                  <Link to="/event">
                      <Button className="action__btn" variant="info">
                          Back to Home
                      </Button>
                  </Link>
              </div>
            </Form>
          </div> 
        } 
    </Layout>
    </>
  );
};


export default EventEdit
import React ,{useState,useEffect} from 'react'
import './news.css';
import { Link , useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from 'react-bootstrap';
import http from "../../http"
import {lang} from '../enum/enum'
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full'
import uploadPlugin from '../../components/ImageUploader/uploadPlugin'
const NewsCreate = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [newses, setNewses] = useState('');
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [parents, setParents] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [getLangError,setLangError] = useState('');
    const [getLoader,setLoader] = useState(false);
    const [imageFormValues,setImageFormValues] = useState('');

    const navigate = useNavigate();
    const cookies = new Cookies();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });
    //obj to array convert
    // let keyarr = []
    // let valarr = []
    // useEffect(()=> {
    //     Object.entries(department).forEach(([key,value])=>{
    //         keyarr.push(key)
    //         valarr.push(value)
    //     })

    //     setKeys(keyarr);
    //     setvalues(valarr);
    // },[department]);

    useEffect(()=>{
        fetchAllParents();
    },[]);

    const fetchAllParents = () => {
        http.get('/news/parent', {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }).then(res=>{
            setParents(res.data.data);
        })
    }

    const getDeptSearch = (val) =>{
        if(val.length > 0){
            http.get(`/department/search/${val}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setDepartments(response.data.data.data);
            })
        }else if(newses.department_id){
            http.get(`/department/get/${newses.department_id}`, {
                headers:{
                 "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                }  
            }).then((response)=>{
                setDepartments([response.data.data]);
            })
        } else {
            setDepartments([]);
        }
    }
    
    function removeEmpty(obj) {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
      }
    
    const onSubmit = (data, e) => {
        setErrorObj({})
        setLangError('')
        const image_url = data.image_url[0];        
        delete newses.image_url;
        data = {...data, ...newses};
        data.user_id = cookies.get('userAuth').id
        data = removeEmpty(data)
        
        data.image_url = image_url;
        let slider_url;
        if(data.slider_url != undefined) {
          slider_url = [...data.slider_url]
        }
        data = removeEmpty(data);
        
        let hasNonImage = slider_url.map((val)=>{
          return ["image/jpeg", "image/png", "image/jpg" ].includes(val.type)
          }           
        ).includes(false)
    
        if(hasNonImage){
          setImageFormValues("The File field must be a file of type: png, jpg, jpeg")
          return
        } 
        data.slider_url = slider_url;

        console.log(data)
        
        setLoader(true)
        http.post('/news/create',data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        })
        .then((res)=>{
            console.log(res);
            setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/news");
              },1000)
        })
        .catch((err)=> {
            setLoader(false);
            // console.log(Object.keys(err.response.data.data))
            if(err.response.data.message == "Validation Error."){
                let errs = err.response.data.data;
                let keys = Object.keys(errs)
                console.log(keys);
                let errors = {};
                keys.map((key)=> errors[key] = errs[key][0])
                console.info(errors);
                setError(err.response.data)
                
                setErrorObj(errors)
              }
              else{
                setLangError(err.response.data.data)
              }
        })
    }
    const onError = (errors, e) => console.log(errors, e);

    const handleChange = (e) =>{
        console.log(e);
        let news = {...newses};
        news[e.target.name] = e.target.value
        setNewses(news);
    }
   
  return (
    <>
    <Layout>
        {/* {
            getSuccess ? 
                (
                    <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0'}}>
                        <Toast.Body >Succesfully Added</Toast.Body>
                    </Toast>
                )
            :null
        } */}

        { getLoader && 
          <div className="preloader">
              <div className="overlay__inner">
                  <div className="overlay__content"><span className="spin"></span></div>
              </div>
          </div>
        }

        <div className="form_design">
            <h2 style={{color: "black",textDecoration: "underline"}}>Create News</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    <Form.Label className="form_label">Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        placeholder="Enter title"
                        // {...register("title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.title? 
                    <span className="text-danger">
                      {getErrorObj.title}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Sub Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="sub_title"
                        placeholder="Enter Sub title"
                        // {...register("sub_title")}
                        onChange={(e) => handleChange(e)}

                    />
                    {getErrorObj.sub_title? 
                    <span className="text-danger">
                      {getErrorObj.sub_title}
                    </span> : null
                    }
                </Form.Group>

                {/* <Form.Group>
                    <Form.Label className="form_label">Department</Form.Label>
                    <Form.Select name="department" {...register("department")} aria-label="Default select example">
                        <option value="">Select Department</option>
                        {
                            keyss.map((k,ele)=>
                            (
                                <option key={k} value={k}>{valuess[ele]}</option>)
                            )
                        }
                    </Form.Select>
                    {getErrorObj.level? 
                    <span className="text-danger">
                      {getErrorObj.level}
                    </span> : null
                    }
                </Form.Group> */}

                <Form.Group>
                    <Form.Label className="form_label">Department</Form.Label>
                    <SelectPicker 
                          name="department_id" 
                          data={departments?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          {...register("department_id")} 
                          onSearch={(val)=>getDeptSearch(val)}
                          onChange={(value) => setNewses({...newses, "department_id": value})}
                      />
                    {getErrorObj.department_id? 
                    <span className="text-danger">
                      {getErrorObj.department_id}
                    </span> : null
                    }
                </Form.Group>
            

                <Form.Group>
                    <Form.Label className="form_label">Image</Form.Label>
                    <Form.Control
                        type="file"
                        name="image_url"
                        placeholder="Enter Image file"
                        {...register("image_url")}
                    />
                    {getErrorObj.image_url? 
                    <span className="text-danger">
                      {getErrorObj.image_url}
                    </span> : null
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Slider File URL</Form.Label>
                    <Form.Control
                        type="file"
                        name="slider_url"
                        placeholder="Enter Slider file"
                        {...register("slider_url")}
                        multiple
                    />
                    {
                        getErrorObj.slider_url?
                            <span className="text-danger">
                            {getErrorObj.slider_url}
                            </span> : null
                    }
                    {
                        imageFormValues ? <span className="text-danger">
                        {imageFormValues}
                        </span> : null
                    }
                </Form.Group>
                

                <Form.Group>
                    <Form.Label className="form_label">Language Parent(English)</Form.Label>
                    <SelectPicker 
                          name="lang_parent_id" 
                          data={parents?.map((item) => ({ label: item.title, value: item.id }))} 
                          style={{ width: "100%" }} 
                          onChange={(value) => setNewses({...newses, "lang_parent_id": value})}
                          />
                       {getErrorObj.lang_parent_id? 
                        <span className="text-danger">
                        {getErrorObj.lang_parent_id}
                        </span> : null
                        }
                        {getLangError? 
                            <span className="text-danger">
                            {getLangError}
                            </span> : null
                        }
                </Form.Group>

                <Form.Group>
                    <Form.Label className="form_label">Language</Form.Label>
                    <SelectPicker 
                          name="lang" 
                          data={Object.keys(lang).map((item) => ({ label: item, value: item }))} 
                          style={{ width: "100%" }} 
                          {...register("lang")} 
                          onChange={(value) => setNewses({...newses, "lang": value})}
                      />
                    {getErrorObj.lang? 
                    <span className="text-danger">
                      {getErrorObj.lang}
                    </span> : null
                    }
                </Form.Group>


                <Form.Group>
                    <Form.Label className="form_label">Description</Form.Label>
                    <CKEditor as="textarea" rows={10}
                        config={{
                            extraPlugins: [uploadPlugin]
                        }}
                        editor={ FullEditor }
                        type="text"
                        name="description"
                        placeholder="Enter Description"
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            console.log( { event, editor, data } );
                            setNewses({...newses, "description": data})
                        } }
                    />
                    {getErrorObj.description? 
                    <span className="text-danger">
                      {getErrorObj.description}
                    </span> : null
                    }
                </Form.Group>

                <div className="mt-4">
                    <Button className="action__btn" variant="primary" type="submit">
                        Save
                    </Button>
                    <Link to="/news">
                        <Button className="action__btn" variant="info">
                            Back to Home
                        </Button>
                    </Link>
                </div>
            </Form>
        </div>  
    </Layout>
    </>
  )
}

export default NewsCreate